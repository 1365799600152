import gsap, { Power4 } from "gsap"
import { ScrollTrigger, SplitText } from "gsap/all"

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(SplitText)

  function fadeinElements() {
    const sections = document.querySelectorAll(".animate-section")

    sections.forEach((section) => {
      const cards = section.querySelectorAll(".animate-fade-in")

      gsap.from(cards, {
        opacity: 0,
        duration: 1,
        stagger: 0.15,
        ease: "power2.inOut",
        scrollTrigger: {
          trigger: section,
          start: "top 90%",
          end: "bottom 80%",
          toggleActions: "play none none",
          markers: false,
        },
      })
    })
  }
  fadeinElements()

  function comeToSurface() {
    const headings = document.querySelectorAll(
      ".secondary-heading, .primary-heading, .third-heading--orange, .reveal"
    )

    headings.forEach((heading) => {
      if (
        heading.classList.contains("fourth-heading") ||
        heading.classList.contains("primary-heading") ||
        heading.classList.contains("third-heading--orange") ||
        heading.classList.contains("reveal")
      ) {
        new SplitText(heading, { type: "words", wordsClass: "line-parent" })
        const lineChild = new SplitText(heading, {
          type: "words",
          wordsClass: "line-child",
        })

        const lineChildren = lineChild.words

        gsap.set(lineChildren, { opacity: 0, yPercent: 100 })

        gsap.to(lineChildren, {
          opacity: 1,
          yPercent: 0,
          duration: 1,
          stagger: 0.1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: heading,
            start: "top 80%",
            end: "bottom 80%",
            toggleActions: "play none none",
          },
        })
      } else if (heading.classList.contains("secondary-heading")) {
        const lineChild = new SplitText(heading, {
          type: "lines",
          linesClass: "line-child",
        })
        new SplitText(heading, { type: "lines", linesClass: "line-parent" })
        const lineChildren = lineChild.lines

        gsap.set(lineChildren, { opacity: 0, yPercent: 100 })

        gsap.to(lineChildren, {
          opacity: 1,
          yPercent: 0,
          duration: 1.2,
          stagger: 0.3,
          ease: "power2.out",
          scrollTrigger: {
            trigger: heading,
            start: "top 80%",
            end: "bottom 80%",
            toggleActions: "play none none",
          },
        })
      }
    })
  }
  comeToSurface()

    const banner = document.querySelector('.espa__banner');
         const hideThreshold = 200;
         // Function to handle scroll event
         function handleScroll() {
             // Get the current scroll position
             const scrollY = window.scrollY || window.pageYOffset;
             // Show or hide the banner based on the scroll position
             if (scrollY > hideThreshold) {
                 banner.style.opacity = 0;
                 banner.style.visibility = 'hidden';
             } else {
                banner.style.opacity = 1;
                banner.style.visibility = 'visible';
             }
         }
         // Attach the scroll event listener to the window
         window.addEventListener('scroll', handleScroll);

})
